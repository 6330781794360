import React, { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const LoginButton = () => {
  const history = useHistory();

  useEffect(() => {
    // Extract token from URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      // Set the token as a secure cookie
      document.cookie = `authToken=${token}; Secure; SameSite=Strict; Path=/`;

      // Redirect to profile page after login success
      history.push('/profile');
    }
  }, [history]);

  const handleLogin = () => {
    window.location.href = 'https://api.goober.my/auth/linkedin';
  };

  return (
    <button onClick={handleLogin} className="login-btn">
      Login with LinkedIn
    </button>
  );
};

export default LoginButton;
