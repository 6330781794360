import React from 'react';

const Error = () => {
  return (
    <div className="error">
      <h1>Something went wrong</h1>
      <p>Please try logging in again.</p>
    </div>
  );
};

export default Error;
