import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profile = ({ token }) => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Retrieve token from localStorage if it's not passed as a prop
    const authToken = token || localStorage.getItem('authToken');

    if (authToken) {
      // Fetch the user profile from the backend
      axios.get('https://api.goober.my/api/profile', {
        headers: { Authorization: `Bearer ${authToken}` }
      })
      .then(response => {
        setProfile(response.data.user);
      })
      .catch(err => {
        setError('Failed to fetch profile. Please log in again.');
      });
    } else {
      setError('No authentication token found.');
    }
  }, [token]);

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!profile) {
    return <div>Loading profile...</div>;
  }

  return (
    <div className="profile">
      <h1>Welcome, {profile.name}!</h1>
      <p>Email: {profile.email}</p>
      {/* Display more profile information here */}
    </div>
  );
};

export default Profile;
