import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Profile from './components/Profile';
import Error from './components/Error';

const App = () => {
  const history = useHistory();
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Extract token from URL
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');

    if (tokenFromUrl) {
      // Store token in localStorage
      localStorage.setItem('authToken', tokenFromUrl);
      setToken(tokenFromUrl);
      history.push('/profile'); // Redirect to profile page
    }
  }, [history]);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="/profile" exact>
            <Profile token={token} />
          </Route>
          <Route path="/error" exact>
            <Error />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
