import React from 'react';
import './LandingPage.css';  // Import CSS for styling
import LoginButton from './LoginButton';

const LandingPage = () => {
  const handleLogin = () => {
    window.location.href = 'https://api.goober.my/auth/linkedin';
  };

  return (
    <div className="landing-container">
      <h1 className="title">Authenticity Unlimited</h1>
      <LoginButton />
    </div>
  );
};

export default LandingPage;
